import { Component, Inject } from '@angular/core';
import { IUserAuthorizations } from 'src/app/login/store/state';
import { AppConfig } from '../shared/app.config';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent {
  navFeatures = [];
  region = this.config.region;
  eligActive = false;
  userAdminActive = false;
  profileActive = false;
  renPlan: boolean;
  producerToolkitUrl: string;

  constructor(@Inject(DOCUMENT) private docRef: Document, private config: AppConfig) {
    const pathSegment = this.docRef.location.pathname.split('/')[1].toLowerCase();
    this.renPlan = pathSegment === 'ren';
    this.producerToolkitUrl = `${this.config.producerToolkitUrl}/${pathSegment}`;
  }

  isDisplayDropdown: boolean;
  userAuths: IUserAuthorizations;
  showAuthorizations: boolean;
  showAdminPlans: boolean;
}
