import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { commonUtil } from '@roosevelt/common-ui-lib/core';

const loginStatus = sessionStorage.getItem('loginStatus');

if (!loginStatus) {
  sessionStorage.setItem('loginStatus', 'TRYING_EXISTING');
}

if (environment.production) {
  enableProdMode();
}
const baseUrl = window.location.pathname.split('/')[1];

commonUtil.bootstrap(
  fetch(baseUrl !== 'fgp' ? environment.configFile : environment.configFileFgp),
  platformBrowserDynamic,
  AppModule)
  .catch(err => console.error(err));
