<nav class="landing-col-1 bmt-navigation">
  <!-- <div class="modal-container sticky" [class.prd-sticky]="region === 'prod'">
    <div class="admin-plan-toggle">
      <div class="admin-plan-toggle-col">
        <label class="modal-btn toggler" for="modal-toggle">
          <svg style="stroke: white;fill: white;" viewBox="0 0 100 80" width="40" height="30">
            <rect width="80" height="5"></rect>
            <rect y="30" width="40" height="5"></rect>
            <rect y="60" width="20" height="5"></rect>
          </svg>
        </label>
      </div>
      <div class="admin-plan-toggle-col">
        <hr class="for-nav-admin">
      </div>
    </div>
  </div> -->
  <ul class="icon-list">
    <li class="icon-list-item" *ngIf="navFeatures.includes('hasProducerToolkitAccess')">
      <a target="_blank" [href]="producerToolkitUrl">
        <span class="icon icon-network"></span>
        <p class="icon-text">Go to Producer Toolkit</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('manageeligibility')">
      <a routerLink="/eligibility/member" [ngClass]="{'left-nav-active': eligActive}">
        <span class="icon icon-family-enrollment"></span>
        <p class="icon-text" [ngClass]="{'left-nav-active': eligActive}">Manage Eligibility</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('benefitsInquiry')">
      <a routerLink="/benefits" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-search"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Benefits Inquiry</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('sat')">
      <a routerLink="/sat" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-search"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">SAT</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('reports')">
      <a routerLink="/reports" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-report"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Reports</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('clientknowledge')">
      <a routerLink="/clientknowledge" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-client-knowledge-reports"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Client Knowledge Reports</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('dentistDirectory') && !renPlan">
      <a routerLink="/dentist" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-dentist"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Dentist Directory</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('summaryOfChanges')">
      <a routerLink="summary-of-changes" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-summary-changes"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Summary of Changes</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('billing')">
      <a routerLink="/billing" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-billing"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Billing</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('userAdmin')">
      <a routerLink="/userAdmin/create" [ngClass]="{'left-nav-active': userAdminActive}">
        <span class="icon icon-group-admin"></span>
        <p class="icon-text" [ngClass]="{'left-nav-active': userAdminActive}">User Admin</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('profile')">
      <a routerLink="/profile/authorizations" [ngClass]="{'left-nav-active': profileActive}">
        <span class="icon icon-user-profile"></span>
        <p class="icon-text" [ngClass]="{'left-nav-active': profileActive}">Profile</p>
      </a>
    </li>
    <li class="icon-list-item" *ngIf="navFeatures.includes('profile')">
      <a routerLink="/help" [routerLinkActive]="['left-nav-active']">
        <span class="icon icon-help"></span>
        <p class="icon-text" [routerLinkActive]="['left-nav-active']">Help</p>
      </a>
    </li>
  </ul>
</nav>
