import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { MagnoliaService } from 'src/app/magnolia/magnolia.service';
import { DOCUMENT } from '@angular/common';
import { OktaAuth } from '@okta/okta-auth-js';
import { commonUtil, Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  luxId = 'BMT-login' + this.constructor.name;
  @ViewChild('loginForm', { static: false }) loginHtmlForm: ElementRef;
  login: UntypedFormGroup;
  showRegistration = false;
  isFirstTimeLogin = false;
  userTypes = [
    { value: null, label: '' },
    { value: 'admin', label: 'Administrator' },
    { value: 'tpa', label: 'Third-party Administrator' },
    { value: 'agency', label: 'Agency' },
    { value: 'agent', label: 'Agent' },
    { value: 'producer', label: 'Producer' }
  ];
  renPlan: boolean;
  loginPageContent: any;

  constructor(
    @Inject(DOCUMENT) private docRef: Document,
    private magnoliaService: MagnoliaService,
    private sanitizer: DomSanitizer,
    private lux: Lux<{}>,
    private router: Router,
    private oktaAuth: OktaAuth) {
    this.renPlan = docRef.location.pathname.split('/')[1].toLowerCase() === 'ren';
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit() {
    (this.docRef.querySelector('#click-to-share') as HTMLElement).style.display = 'block';
    this.magnoliaService.getMagnoliaContent('login').subscribe((content) => {
      this.loginPageContent = this.sanitizer.bypassSecurityTrustHtml(content);
    });

    from(commonUtil.authentication.status(this.oktaAuth)).subscribe(status => {
      // console.log(status);
      this.router.navigateByUrl('/oidc-callback');
    });
  }

  firstTimeLogin() {
    this.isFirstTimeLogin = true;
  }

}
