import { IClients } from '@roosevelt/client-ui-lib/lib/client-search/store/state';
import {
  IAdminAndUserAuthResponse,
  IAdminAndUserAuthsReq,
  IAdminClients,
  IAdminUserList,
  ICreateManageUserState,
  ICreateUserReq,
  IManageuser,
  IManageUserAuthsReq,
  IUserInfoObj,
  IUserList,
  IUserListReq
} from './state';

export const getCreateUserReq =
    (state: { createManageUserState: ICreateManageUserState }): ICreateUserReq => state.createManageUserState.createUserReq;

export const getErrors = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.errors;

export const isUserGettingCreated = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.isUserGettingCreated;

export const isUserCreated = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.userCreated;

export const getUsesrlistErrors = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.errorsFetchingUsers;
export const isFetchingUserLists = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.isFetchingUsers;
export const getUserLists = (state: { createManageUserState: ICreateManageUserState }): IAdminUserList => state.createManageUserState.usersOfAdmin;

export const getAdminUserListReq = (state: { createManageUserState: ICreateManageUserState }): IUserListReq => state.createManageUserState.adminUsersReq;

export const getAdminRoleReq = (state: { createManageUserState: ICreateManageUserState }): IUserListReq => {
    return {
        pagination: {
            limit: 1,
            offset: 0
        },
        sort: [],
        searchType: 'self'
    };
};


export const getLoggedUserRole = (state: { createManageUserState: ICreateManageUserState }): IUserList[] => state.createManageUserState.adminRole;

export const getManagingUserInfo = (state: { createManageUserState: ICreateManageUserState }): IManageuser => state.createManageUserState.manageUser;

// Get the clients for the admin
export const getAdminClients = (state: { createManageUserState: ICreateManageUserState }): IAdminClients[] => state.createManageUserState.adminClientsInfo.adminClients;

export const isFetchingContext = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.adminClientsInfo.fetchingClients;

// Get Admin Auths

export const getAdminAndUserAuthsReq = (state: { createManageUserState: ICreateManageUserState }): IAdminAndUserAuthsReq => state.createManageUserState.adminAndUserAuths.authRequest;

export const getAdminAndUserAuthsResposne = (state: { createManageUserState: ICreateManageUserState }): IAdminAndUserAuthResponse => state.createManageUserState.adminAndUserAuths.authResponse;

export const isFetchingAuthsToEdit = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.adminAndUserAuths.fetchingAuths;

export const errorFetchingAuthsToEdit = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.adminAndUserAuths.errors;


// Below are gonna be used to just view the authorizations
export const getAdminAndUserAuthsReqForView = (state: { createManageUserState: ICreateManageUserState }): IAdminAndUserAuthsReq => state.createManageUserState.viewAdminAndUserAuths.authRequest;

export const getAdminAndUserAuthsResposneForView = (state: { createManageUserState: ICreateManageUserState }): IAdminAndUserAuthResponse => state.createManageUserState.viewAdminAndUserAuths.authResponse;

export const isFetchingAuthsToView = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.viewAdminAndUserAuths.fetchingAuths;

export const errorFetchingAuthsToView = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.viewAdminAndUserAuths.errors;



// Below are used to manage the user auths
export const getManageUserAuthsReq = (state: { createManageUserState: ICreateManageUserState }): IManageUserAuthsReq => state.createManageUserState.manageUserAuths.authRequest;

export const updatingAuthInProgress = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.manageUserAuths.updatingAuths;

export const errorsInUpdatingAuths = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.manageUserAuths.errors;

export const getManageUserAuthsResp = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.manageUserAuths.authResponse;

// Get clients of the admin
export const getClientsOfAdmin = (state: {createManageUserState: ICreateManageUserState}): IClients => state.createManageUserState.clientsForAmdin.clientsInfo;

export const errorsFetchingClients = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.clientsForAmdin.clientOrSubClientSearchErrors;

export const fetchingClients = (state: {createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.clientsForAmdin.isFetchingClient;

// Get Updating user state
export const updatingUserInfo = (state: {createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.updatingUser.updatingUser;
export const updatingUserStatus = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.updatingUser.success;
export const errorsUpdatingUser = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.updatingUser.errors;

// Get user info state
export const fetchingUserInfo = (state: {createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.selectedUserInfo.fetchingUserInfo;
export const selectedUserInfo = (state: {createManageUserState: ICreateManageUserState}): IUserInfoObj[] => state.createManageUserState.selectedUserInfo.userInfo;
export const errorsfetchingUserInfo = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.selectedUserInfo.errors;

// Updating password
export const isUpdatingPassword = (state: {createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.isUpdatingPassword;
export const errorUpdatingPassword = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.errorUpdatePassword;
export const updatedPasswordSuccessfully = (state: {createManageUserState: ICreateManageUserState}): string => state.createManageUserState.updatedPasswordSuccessfully;
