import { IClients } from '@roosevelt/client-ui-lib/lib/client-search/store/state';

export const initialCreateManageUserState: ICreateManageUserState = {
  isUpdatingPassword: false,
  errorUpdatePassword: '',
  updatedPasswordSuccessfully: '',
  isUserGettingCreated: false,
  userCreated: false,
  createUserReq: {
    user: {
      userIdentifier: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      companyName: ''
    }
  },
  errors: '',
  isFetchingUsers: false,
  usersOfAdmin: {
    pagination: {
      limit: 0,
      offset: 0,
      totalRecords: 0
    },
    users: []
  },
  adminRole: [],
  errorsFetchingUsers: '',
  adminUsersReq: {
    pagination: {
      limit: 0,
      offset: 0
    },
    sort: [],
    searchType: ''
  },
  getAdmnRoleReq: {
    pagination: {
      limit: 0,
      offset: 0
    },
    sort: [],
    searchType: ''
  },
  manageUser: {
    selectedUserId: '',
    selectedAction: '',
    selectedUserType: '',
    adminRole: '',
    contextOfAdmin: '',
    planAcronyms: []
  },
  adminAndUserAuths: {
    errors: '',
    fetchingAuths: false,
    authRequest: {
      pagination: {
        limit: 0,
        offset: 0
      },
      sort: [],
      userIdentifier: '',
      payer: '',
      payerAdmin: '',
      groupSpecifiedIdentifier: '',
      subGroupSpecifiedIdentifier: '',
      groupAccessLevel: ''
    },
    authResponse: {
      adminRelatedAuthorizations: {
        eligibilityAccessLevel: '',
        clientKnowledgeIndicator: false,
        billingReportAccessIndicator: false,
        customClientReportAccessIndicator: false,
        claimDetailReportAccessIndicator: false,
        satAccessLevel: '',
        satAccessReportIndicator: false,
      },
      userRelatedAuthorizations: [],
      pagination: {
        limit: 0,
        offset: 0
      },
      sort: []
    }
  },
  viewAdminAndUserAuths: {
    errors: '',
    fetchingAuths: false,
    authRequest: {
      pagination: {
        limit: 0,
        offset: 0
      },
      sort: [],
      userIdentifier: '',
      payer: '',
      payerAdmin: '',
      groupSpecifiedIdentifier: '',
      subGroupSpecifiedIdentifier: '',
      groupAccessLevel: ''
    },
    authResponse: {
      adminRelatedAuthorizations: {
        eligibilityAccessLevel: '',
        clientKnowledgeIndicator: false,
        billingReportAccessIndicator: false,
        customClientReportAccessIndicator: false,
        claimDetailReportAccessIndicator: false,
        satAccessLevel: '',
        satAccessReportIndicator: false,
      },
      userRelatedAuthorizations: [],
      pagination: {
        limit: 0,
        offset: 0
      },
      sort: []
    }
  },
  manageUserAuths: {
    updatingAuths: false,
    authRequest: {
      userIdentifier: '',
      userType: ''
    },
    authResponse: '',
    errors: ''
  },
  clientsForAmdin: {
    isFetchingClient: false,
    clientsInfo: {
      clients: [],
      pagination: {
        limit: 0,
        offset: 0
      },
      sort: []
    },
    clientOrSubClientSearchErrors: ''
  },
  adminClientsInfo: {
    fetchingClients: false,
    errors: '',
    adminClients: []
  },
  updatingUser: {
    updatingUser: false,
    errors: '',
    success: ''
  },
  selectedUserInfo: {
    fetchingUserInfo: false,
    errors: '',
    userInfo: []
  }
};

export interface ICreateManageUserState {
  isUpdatingPassword: boolean;
  errorUpdatePassword: string;
  updatedPasswordSuccessfully: string;
  isUserGettingCreated: boolean;
  createUserReq: ICreateUserReq;
  errors: string;
  userCreated: boolean;
  isFetchingUsers: boolean;
  usersOfAdmin: IAdminUserList;
  errorsFetchingUsers: string;
  adminUsersReq: IUserListReq;
  getAdmnRoleReq: IUserListReq;
  adminRole: IUserList[];
  manageUser: IManageuser;
  adminAndUserAuths: {
    fetchingAuths: boolean;
    authRequest: IAdminAndUserAuthsReq;
    authResponse: IAdminAndUserAuthResponse;
    errors: string;
  };
  viewAdminAndUserAuths: {
    fetchingAuths: boolean;
    authRequest: IAdminAndUserAuthsReq;
    authResponse: IAdminAndUserAuthResponse;
    errors: string;
  };
  manageUserAuths: {
    updatingAuths: boolean;
    authRequest: IManageUserAuthsReq;
    authResponse: string;
    errors: string;
  };
  clientsForAmdin: {
    isFetchingClient: boolean;
    clientsInfo: IClients;
    clientOrSubClientSearchErrors: string;
  };
  adminClientsInfo: {
    fetchingClients: boolean;
    errors: string;
    adminClients: IAdminClients[];
  };
  updatingUser: {
    updatingUser: boolean;
    errors: string;
    success: string;
  };
  selectedUserInfo: {
    fetchingUserInfo: boolean;
    errors: string;
    userInfo: IUserInfoObj[];
  };
}

export interface IUserInfoObj {
  userStatus: string;
  userIdentifier: string;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string;
  isAccountLocked: boolean;
}

export interface IManageuser {
  selectedUserId: string;
  selectedAction: string;
  selectedUserType: string;
  adminRole: string;
  contextOfAdmin: string;
  planAcronyms?: string[];
}

export interface IAdminClients {
  ADMINPLAN: string;
  CLIENT?: string;
  PLAN: string;
}

export interface IAdminUserList {
  pagination: IPagination;
  users: IUserList[];
}

export interface IUserList {
  userName: string;
  userStatus: string;
  userType: string;
  firstName ?: string;
  lastName ?: string;
  roleName: string;
  emailAddress: string;
  hasProducerToolkitAuth: string;
}

export interface IUserListReq {
  pagination: IPagination;
  sort: ISort[];
  searchType: string;
}

export interface ISort {
  order: string;
  fieldName: string;
}

export interface IPagination {
  offset: number;
  limit: number;
  totalRecords ?: number;
}

export interface ICreateUserReq {
  user: {
    userIdentifier: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    companyName: string;
  };
}

export interface IAdminAndUserAuthsReq {
  userIdentifier: string;
  payer: string;
  payerAdmin: string;
  groupSpecifiedIdentifier?: string;
  subGroupSpecifiedIdentifier?: string;
  groupAccessLevel: string;
  subgroupAccessLevel?: string;
  pagination?: IPagination;
  sort?: ISort[];
}

export interface IManageUserAuthsReq {
  userIdentifier: string;
  userType: string;
  authorizations?: IManageUserAuthObj [];
}

export interface IManageUserAuthObj {
  payers: string [];
  groupAccessLevel: string;
  groupSpecifiedIdentifier?: string;
  subgroupAccessLevel?: string;
  subgroupAuthorizations?: IManageUserSubgroupAuthObj[];
}

export interface IManageUserSubgroupAuthObj {
  subgroupSpecifiedIdentifier?: string;
  eligibilityAccessLevel: string;
  satAccessLevel: string;
  clientKnowledgeIndicator: boolean;
  billingReportAccessIndicator: boolean;
  customClientReportAccessIndicator: boolean;
  claimDetailReportAccessIndicator: boolean;
}

export interface IAdminAndUserAuthResponse {
  adminRelatedAuthorizations: IBMTAccessLevels;
  userRelatedAuthorizations: ISubGroupAccessLevels [];
  relatedAuthorizations?: ISubGroupAccessLevels [];
  sort: ISort[];
  pagination: IPagination;
}

export interface IBMTAccessLevels {
  eligibilityAccessLevel: string;
  clientKnowledgeIndicator: boolean;
  billingReportAccessIndicator: boolean;
  customClientReportAccessIndicator: boolean;
  claimDetailReportAccessIndicator: boolean;
  satAccessLevel: string;
  satAccessReportIndicator: boolean;
  subGroupAuthorizations?: ISubGroupAccessLevels [];
}

export interface ISubGroupAccessLevels extends IBMTAccessLevels {
  subGroupSpecifiedIdentifier?: string;
  subGroupName?: string;
  userPrivilegeSelected?: boolean;
}

// export interface IClients {
//   clients: IClient[];
//   pagination: IPagination;
//   sort: ISortItem[];
//   hasMoreClients?: boolean;
// }

// export interface IClient {
//   name?: string;
//   planAcronym: string | string[];
//   specifiedId: string;
//   id: number;
//   status?: IStatus;
//   selected?: boolean;
//   isrowselected?: boolean;
//   workflowMode?: string;
// }
