import { Component, HostListener, Inject, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from './shared/app.config';
import { filter, first, map, startWith } from 'rxjs/operators';
import { IAuthInfo, ILoginState, IUserAuthorizations } from './login/store/state';
import { MagnoliaService } from './magnolia/magnolia.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { getImpersonationUser, getSessionId, getUserAuths, getUserInfo } from 'src/app/login/store/selectors';
import { UserAuthConfig as EligUserAuth } from '@roosevelt/elig-ui-lib';
import { UserAuthConfig as ClientUserAuth } from '@roosevelt/client-ui-lib';
import { UserAuthConfig as BillingUserAuth } from '@roosevelt/billing-lib';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAlertsState, IGeneralAlerts } from './store/alerts/state';
import { alertErrors, getBMTAlerts } from './store/alerts/selector';
import { IAppAuthorizations } from './authorizations/state';
import { isFetchingContext, isFetchingUserLists } from './store/createManageUser/selector';
import { ICreateManageUserState } from './store/createManageUser/state';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { HeaderComponent } from '@roosevelt/common-ui-lib/header';
import { BasicAuthConfig, CommonUserService, commonUtil, Lux } from '@roosevelt/common-ui-lib/core';
import { NotificationService } from './shared/services/notification.service';

declare var dT_;

@Pipe({ standalone: true, name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  luxId = 'BMT-AppComponent' + new Date().toISOString();
  isImpersonating = false;
  userInfo$: Observable<IAuthInfo> = this.lux.get(getUserInfo, this.luxId);
  userAuths$: Observable<{ [key: string]: string[] }> = this.lux.get(getUserAuths, this.luxId);
  bmtAlerts$: Observable<IGeneralAlerts[]> = this.lux.get(getBMTAlerts, this.luxId);
  alertErrors$: Observable<string> = this.lux.get(alertErrors, this.luxId);
  isFetchingContext$: Observable<boolean> = this.lux.get(isFetchingContext, this.luxId);
  isFetchingUserLists$: Observable<boolean> = this.lux.get(isFetchingUserLists, this.luxId);
  getSessionId$: Observable<string> = this.lux.get(getSessionId, this.luxId);
  isFetchingContext: boolean;
  title = 'bmt-ui';
  region = this.config.region;
  activeUrl: string;
  magnoliaHeaderContent: any;
  magnoliaFooterContent: any;
  registrationPage: boolean;
  urlNotDefined = false;
  showBmtAlerts = false;
  bmtAlerts: IGeneralAlerts[];
  bmtAlertErrors: string;
  userLoginInProgress: boolean;
  impersonationUser: string;
  auth: IUserAuthorizations;
  isFetchingUserLists: boolean;
  session: string;
  postLogoutRedirectUrl: string;
  usersId: string;

  subscriptions: Subscription[] = [];
  inactivityDisplay: Date = new Date(0, 0, 0, 0, 0, 0, 0);
  countdownTotalInSeconds = 0;
  countdown = 0;
  refreshUrl: SafeResourceUrl = undefined as any;
  trustGuardUrl: string;
  themeStyleLinks: any;

  constructor(
    @Inject(DOCUMENT) private docRef: Document,
    private env: AppConfig, private router: Router,
    private analyticsService: GoogleAnalyticsService,
    private lux: Lux<{ login: ILoginState, bmtAlertsState: IAlertsState, appUiAuth: IAppAuthorizations, createManageUserState: ICreateManageUserState }>,
    private config: AppConfig,
    private magnoliaService: MagnoliaService,
    private sanitized: DomSanitizer,
    @Inject(BasicAuthConfig) private basicAuthConfig: BasicAuthConfig,
    @Inject(EligUserAuth) private eligUserAuth: EligUserAuth,
    @Inject(ClientUserAuth) private clientUserAuth: ClientUserAuth,
    @Inject(BillingUserAuth) private billingUserAuth: BillingUserAuth,
    private http: HttpClient,
    private oktaAuth: OktaAuth,
    private commonUserService: CommonUserService,
    @Inject(DOCUMENT) private doc: Document,
    public notificationService: NotificationService
  ) {
    if (this.doc.location.pathname.split('/')[1].toLowerCase() === 'ren') {
      this.themeStyleLinks = this.sanitized.bypassSecurityTrustHtml(`<link rel="stylesheet" href="assets/rlha.css"><link rel='stylesheet' href='./assets/css/base.css'><link rel='stylesheet' href='./assets/css/renBase.css'>`);
    } else {
      this.themeStyleLinks = this.sanitized.bypassSecurityTrustHtml(`<link rel="stylesheet" href="assets/delta.css"><link rel='stylesheet' href='./assets/css/base.css'>`);
    }
    this.trustGuardUrl = this.env.trustGuardSecuritySeals[this.doc.location.pathname.split('/')[1].toLowerCase()];
    // loads dynatrace tracking
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders);
    }
    this.router.events.pipe(
      filter((val) => val instanceof NavigationEnd),
      map((x: NavigationEnd) => x.url),
      startWith(this.router.url)
    ).subscribe(url => {
      this.activeUrl = url;
      this.urlNotDefined = (!this.router.config.map(routePath => routePath.path).includes(url.split('/')[1])
        && (url.includes('registration') && url.includes('eligibility/member') && url.includes('eligibility/add-member') && url.includes('userAdmin/create') && url.includes('userAdmin/manage')));
      this.registrationPage = url.includes('registration');
      if (!this.impersonationUser) {
        this.lux.get(getImpersonationUser).pipe(first()).subscribe(name => this.impersonationUser = name);
        this.isImpersonating = !!sessionStorage.getItem('impersonate_user');
      }
      if (url !== '/login' && url !== '/' && (!this.subscriptions || !this.subscriptions.length)) {
        this.setUpInactiveAndRefresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit() {
    this.postLogoutRedirectUrl = this.env.postLogoutRedirectEndpoint + this.docRef.location.pathname.split('/')[1] + '/';
    // To hide the progress bar in index.html
    (this.docRef.querySelector('#initail-loader-container') as HTMLElement).style.display = 'none';

    // Global Analytics site tag
    this.analyticsService.googleAnalyticsG4();

    this.magnoliaHeaderContent = undefined;
    this.magnoliaService.getMagnoliaContent('headerFooter').subscribe((content) => {
      this.magnoliaFooterContent = this.sanitized.bypassSecurityTrustHtml(content.substring(
        content.lastIndexOf('<footerContent>') + 15,
        content.lastIndexOf('</footerContent>')
      ));
    });
    !window.location.pathname.split('/')[2] && this.router.navigate(['/login']);
    this.userInfo$.subscribe(user => {
      if (user && user.id) {
        this.basicAuthConfig.clientId = user.cid;
        this.usersId = user.id;
        this.magnoliaService.getMagnoliaContent('headerFooter').subscribe((content) => {
          content = content.replace('profileName', this.commonUserService?.getFullName() || '');
          this.magnoliaHeaderContent = this.sanitized.bypassSecurityTrustHtml(content.substring(
            content.lastIndexOf('<headerContent>') + 15,
            content.lastIndexOf('</headerContent>')
          ));
        });
      }
    });
    this.userAuths$.subscribe(adminPlansAndPlans => {
      this.eligUserAuth.userAuthorizations = this.clientUserAuth.userAuthorizations = this.billingUserAuth.userAuthorizations = adminPlansAndPlans;
    });
    this.bmtAlerts$.subscribe(alerts => this.bmtAlerts = alerts);
    this.alertErrors$.subscribe(errors => this.bmtAlertErrors = errors);
    this.isFetchingContext$.subscribe(progress => this.isFetchingContext = progress);
    this.isFetchingUserLists$.subscribe(progress => this.isFetchingUserLists = progress);
    this.getSessionId$.subscribe(session => this.session = session);
  }

  closeThisTab() {
    window.close();
  }

  viewAlerts() {
    this.showBmtAlerts = true;
  }

  headerClick(event) {
    if (event.target.classList.contains('magnolia-logout-btn')) {
      this.logOut();
    }
  }

  @HostListener('window:storage', ['$event']) manageAuthState(event: StorageEvent): void {
    if (event.key === 'logout-catalyst' && event.newValue) {
      this.logOut();
    }
  }

  logOut() {
    localStorage.setItem('logout-catalyst', this.config.appName);
    localStorage.setItem('logout-catalyst', '');
    sessionStorage.clear();
    localStorage.clear();
    this.oktaAuth.options.postLogoutRedirectUri = this.postLogoutRedirectUrl;
    commonUtil.authentication.signOut(this.oktaAuth);
  }

  setUpInactiveAndRefresh() {
    const _inactivityTimeout = 30 * 1000 * 60;
    const { subscriptions, countdown$, shouldLogOut$, inactivityDisplay, countdownTotalInSeconds } = HeaderComponent.setUpTimeout(_inactivityTimeout);
    this.subscriptions = [
      ...this.subscriptions,
      ...subscriptions,
      countdown$.subscribe(x => this.countdown = x),
      shouldLogOut$.subscribe(() => this.logOut())
    ];
    this.inactivityDisplay = inactivityDisplay;
    this.countdownTotalInSeconds = countdownTotalInSeconds;
  }

}
